.main {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.index {
	font-size: 0.8em;
	margin: 0;
	text-transform: uppercase;
	font-weight: bold;
	opacity: var(--opacity-header-puzzle);
	color: var(--color-header-puzzle);
}

.title {
	font-size: 2em;
	text-align: center;
	margin: 0;
	color: var(--color-header-title);
	white-space: nowrap;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: calc(100vw - 50px);
}
