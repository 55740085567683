@import "../styles/easings.scss";
@import "../styles/mixins.scss";

$border-radius: 2px;

.main {
	display: inline-flex;
	margin: 0;
	padding: 0;
	gap: 6px;
	justify-content: center;
	user-select: none;
}

.starBox {
	position: relative;
	border-radius: $border-radius;
	display: flex;
	width: 30px;
	height: 30px;
	overflow: hidden;

	&.transparent {
		opacity: var(--opacity-keyboard-key-disabled);
	}
}

.starBoxIcon {
	position: absolute;
	width: 100%;
	height: 100%;

	.starBox & {
		color: currentColor;
	}
}
