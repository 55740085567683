@mixin basic-reset-touch-selectors {
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: var(--color-basic-transparent);
	-moz-tap-highlight-color: var(--color-basic-transparent);
}

@mixin basic-reset-button {
	all: unset;
	outline: revert;
	box-sizing: border-box;
}
