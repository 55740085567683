.row {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 2em 0 0;
	flex-wrap: nowrap;
	gap: 1em;

	.columnLeft {
		flex: 1 1 50%;
		display: flex;
	}

	.columnRight {
		flex: 1 1 50%;
		& * {
			font-size: 1.1em;
		}
	}
}
