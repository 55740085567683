.table {
	background-color: var(--color-game-background);
	width: 100%;
	border-radius: 4px;
	padding: 4px;

	& td {
		line-height: 1em;
		height: 2em;
		align-content: center;
		text-align: center;
		width: 50%;
		padding: 0 5px;
		font-size: 18px;

		&:nth-of-type(1) {
			text-align: right;
		}

		&:nth-of-type(2) {
			text-align: left;
			font-weight: bold;
		}
	}
}

.stars {
	vertical-align: middle;
	transform: scale(0.8);
	transform-origin: center right;
}

.archive {
	background-color: var(--color-game-background);
	width: 100%;
	border-radius: 3px;
	padding: 4px;
	display: flex;
	flex-direction: column;
	gap: 2px;

	& .row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		border-radius: 4px;
		padding: 4px;
		background-color: var(--color-field-cell-background);

		font-size: 16px;
		cursor: pointer;

		&.won {
			background-color: var(--color-field-cell-background-right);
		}

		&.lost {
			background-color: var(--color-field-cell-background-wrong);
		}

		& > * {
			padding: 2px 8px;
		}

		& .left {
			text-align: right;
			flex: 0 0 32%;
		}

		& .right {
			text-align: left;
			font-weight: bold;
			flex: 0 0 68%;
			font-size: 1.25em;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		& .index {
			margin: 0;
			padding: 0;
			font-weight: bold;
			font-size: 1.5em;
			line-height: 1em;
		}

		& .date {
			margin: 0;
			padding: 0;
			font-size: 0.9em;
			opacity: 0.75;
		}

		& .tag {
			font-size: 0.7em;
			font-weight: bold;
			border-radius: 2px;
			color: var(--color-toast-text);
			background-color: var(--color-toast-background);
			padding: 0px 5px;
			margin-left: 8px;
		}

		& .spacer {
			flex-grow: 1;
		}
	}
}
