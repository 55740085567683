.main {
	width: 0.65em;
	height: 0.65em;
}

.shapeCircle {
	border-radius: 50%;
}

.shapeSquare {
}
