$easing-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easing-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easing-out-expo: cubic-bezier(0.06, 0.835, 0.355, 1);
$easing-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easing-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$easing-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easing-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$easing-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easing-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easing-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easing-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easing-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easing-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easing-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easing-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easing-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$easing-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easing-out-back-short: cubic-bezier(0.4, 1.4, 0.72, 0.98);
$easing-out-back-slow-settle: cubic-bezier(0.15, 1.21, 0.29, 1.07);
$easing-out-back-big: cubic-bezier(0.4, 2, 0.32, 1.275);
$easing-out-back-small: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$easing-out-back-long-bounce: cubic-bezier(0.07, 0.46, 0.15, 1.32);
