.symbolColor {
	animation-duration: 133.7s;
	animation-delay: 0s;
	animation-name: color-loop;
	animation-iteration-count: infinite;
}

@keyframes color-loop {
	0% {
		fill: var(--symbol-color-0);
	}
	8.3333% {
		fill: var(--symbol-color-1);
	}
	16.6666% {
		fill: var(--symbol-color-2);
	}
	25% {
		fill: var(--symbol-color-3);
	}
	33.3333% {
		fill: var(--symbol-color-4);
	}
	41.6666% {
		fill: var(--symbol-color-5);
	}
	50% {
		fill: var(--symbol-color-6);
	}
	58.3333% {
		fill: var(--symbol-color-7);
	}
	66.6666% {
		fill: var(--symbol-color-8);
	}
	75% {
		fill: var(--symbol-color-9);
	}
	83.3333% {
		fill: var(--symbol-color-10);
	}
	91.6666% {
		fill: var(--symbol-color-11);
	}
	100% {
		fill: var(--symbol-color-0);
	}
}
