@import "../styles/easings.scss";
@import "../styles/sizes.scss";

$toast-height: 80px;
$toast-gap: 30px;

.main {
	position: absolute;
	width: 100%;
	top: 0;
	padding: 0px 40px;
}

.toast {
	position: relative;
	width: 100%;
	height: 0px;

	user-select: none;
	transition: all 0.5s $easing-out-cubic;
	opacity: 0;

	&.showing {
		height: calc($toast-height + $toast-gap);
		opacity: 1;
	}

	&.hiding {
		height: 0;
		opacity: 0;
	}

	& .inner {
		position: absolute;
		width: 100%;
		height: $toast-height;
		bottom: 0;
		padding: 0 $game-padding-sides;
		background-color: var(--color-toast-background);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		box-shadow: var(--color-toast-shadow) 2px 2px 6px;

		font-size: 22px;
		color: var(--color-toast-text);
		text-align: center;

		transition: all 0.5s $easing-out-cubic;
	}
}
