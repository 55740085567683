@import "../styles/sizes.scss";

$iconSize: 18px;
$iconFullSize: calc($iconSize + $game-padding-sides * 2);

.main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	align-self: stretch;
	margin: calc($game-padding-sides * -1);
}

.iconSpacer {
	padding: $game-padding-sides;
	width: $iconFullSize;
	height: $iconFullSize;
}

.iconSpacerLink {
	cursor: pointer;
}

.menuIcon {
	width: $iconSize;
	height: $iconSize;
	color: var(--color-menu-icon);
}

.logoArea {
	display: flex;
	align-content: center;
	justify-content: center;
	flex: 1;
	height: 14px;

	& > * {
		height: 100%;
	}
}
