@import "../styles/easings.scss";
@import "../styles/sizes.scss";

$cell-hit-slop: calc($field-cell-gap / 2);

.main {
	cursor: pointer;
	border-radius: 3px;
	background-color: var(--color-field-cell-background);
	width: $field-cell-width;
	height: $field-cell-width;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	box-shadow: var(--color-field-cell-shadow) 1px 1px 2px;
	position: relative;
	transform: scale(1.001);
}

.border {
	background: transparent;
	position: absolute;
	border-radius: inherit;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	outline-width: 0;
	outline-style: solid;
	opacity: 0;
	z-index: 1;
	transition: opacity 0.25s linear;

	.focused > & {
		opacity: var(--opacity-field-cell-selected-border);
		outline-width: 2px !important;
	}

	.similarToFocused > & {
		opacity: var(--opacity-field-cell-selected-border);
		outline-width: 1px;
	}
}

.background {
	background: transparent;
	position: absolute;
	border-radius: inherit;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.rightCell > & {
		background-color: var(--color-field-cell-background-right);
		border: 1px solid var(--color-field-cell-border-right);
	}

	.wrongCell > & {
		background-color: var(--color-field-cell-background-wrong);
		border: 1px solid var(--color-field-cell-border-wrong);
	}
}

.hitArea {
	background: transparent;
	position: absolute;
	top: -$cell-hit-slop;
	left: -$cell-hit-slop;
	right: -$cell-hit-slop;
	bottom: -$cell-hit-slop;
}

.label {
	display: block;
	text-transform: uppercase;
	font-size: 36px;
	font-weight: bold;
	z-index: 1;
}

.disabled {
	cursor: default !important;
}

.cellHiding {
	animation-duration: 0.15s;
	animation-delay: 0s;
	animation-name: cell-hide;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	animation-timing-function: $easing-in-quad;
}

.cellRevealing {
	animation-duration: 0.15s;
	animation-delay: 0s;
	animation-name: cell-reveal;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	animation-timing-function: $easing-out-quad;
}

@keyframes cell-hide {
	0% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(90deg);
	}
}

@keyframes cell-reveal {
	0% {
		transform: rotateY(-90deg);
	}
	100% {
		transform: rotateY(0deg);
	}
}
