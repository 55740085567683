@import-normalize;

@import "@fontsource/source-sans-3/latin-400.css";
@import "@fontsource/source-sans-3/latin-700.css";

@import "./styles/global/colors.scss";

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Source Sans 3", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--color-page-background);
}
