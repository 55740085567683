@import "../styles/sizes.scss";

.main {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: $keyboard-gap-vertical;
	user-select: none;
}

.row {
	display: flex;
	justify-content: center;
	gap: $keyboard-gap-horizontal;
}
