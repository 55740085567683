@import "./styles/sizes.scss";

.main {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: $page-height;
}

.game {
	overflow: hidden;
	position: relative;
	background-color: var(--color-game-background);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: $game-padding-sides;
	width: $game-width;
	height: $game-height;
	outline: 0;
}

.chances {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.chancesStars {
	color: var(--color-keyboard-key-background);

	&.empty {
		opacity: var(--opacity-keyboard-key-disabled);
	}
}
