$game-padding-sides: 16px;
$num-letters: 5;
$game-width: min(100vw, 440px);
$game-height: min(100svh, 932px);
$page-height: max(100svh, $game-height);
$field-width: calc($game-width - $game-padding-sides * 2);
$field-cell-gap: 10px;
$field-cell-width: calc(($field-width - $field-cell-gap * ($num-letters - 1)) / $num-letters);
$keyboard-gap-horizontal: 4px;
$keyboard-gap-vertical: 6px;
