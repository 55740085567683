@import "../styles/easings.scss";
@import "../styles/mixins.scss";

.main {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--color-sliding-message-background);
	justify-content: space-evenly;
	align-items: center;
	transition: all 0.4s $easing-out-quad;
	transform: scale(1.001);
	border-radius: 2px;
	user-select: none;

	&.hidden {
		transform: scale(0.9);
		filter: blur(10px);
		opacity: 0;
		pointer-events: none;
	}
}

.message {
	font-weight: bold;
	font-size: 20px;
	text-transform: uppercase;
	color: var(--color-sliding-message-text);
	text-align: center;
}

.stars {
	color: white;
}

.shareButton {
	@include basic-reset-button;

	font-weight: bold;
	font-size: 18px;
	color: var(--color-sliding-message-text);
	cursor: pointer;
	border: 1px solid var(--color-sliding-message-text);
	padding: 5px 10px 5px;
	border-radius: 2px;
	display: flex;
	gap: 8px;
	align-items: center;

	& .icon {
		width: 24px;
		height: 24px;
	}

	& .text {
		padding-top: 2px;
	}
}
