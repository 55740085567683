// Basics
$color-basic-black: #000000;
$color-basic-white: #ffffff;

:root {
	--color-basic-white: #{$color-basic-white};
}

@mixin vars-colors-light {
	--color-page-background: #{$color-basic-white};

	--color-game-background: #eceef3;

	--color-menu-icon: #a3adbf;

	--color-header-title: #464e5c;
	--color-header-puzzle: #464e5c;
	--opacity-header-puzzle: 0.5;

	--color-field-cell-shadow: rgba(0, 0, 0, 0.075);
	--color-field-cell-background: #ffffff;
	--color-field-cell-background-right: rgba(0, 128, 0, 0.15);
	--color-field-cell-border-right: rgba(0, 128, 0, 0.075);
	--color-field-cell-background-wrong: rgba(255, 0, 0, 0.15);
	--color-field-cell-border-wrong: rgba(255, 0, 0, 0.075);

	--opacity-field-cell-selected-border: 1;

	--color-keyboard-key-text: #4e5867;
	--color-keyboard-key-background: #b7c5d5;
	--color-keyboard-key-background-wrong: rgba(255, 0, 0, 0.25);

	--opacity-keyboard-key-disabled: 0.3;

	--color-chances-stars: #293641;

	--color-menu-foreground-text: #5d6476;
	--color-menu-foreground-close: #c2c6cf;
	--color-menu-background: #ffffff;
	--color-menu-overlay: #000000;
	--opacity-menu-overlay: 0.6;

	--color-dialog-foreground-text: var(--color-menu-foreground-text);
	--color-dialog-foreground-close: var(--color-menu-foreground-close);
	--color-dialog-background: var(--color-menu-background);
	--color-dialog-overlay: var(--color-menu-overlay);
	--opacity-dialog-overlay: 0.85;

	--color-sliding-message-background: #a94498;
	--color-sliding-message-text: white;

	--color-toast-text: white;
	--color-toast-background: #a94498;
	--color-toast-shadow: rgba(0, 0, 0, 0.4);
}

@mixin vars-colors-light-highContrast {
	--color-menu-icon: #838dbf;

	--color-header-title: #262e3c;
	--color-header-puzzle: #262e4c;
	--opacity-header-puzzle: 0.9;

	--color-keyboard-key-text:#2e3847;

	--color-menu-foreground-text: #3d4456;
	--color-menu-foreground-close: #a2a6af;
}

@mixin vars-colors-dark {
	// Dark theme
	--color-page-background: #{$color-basic-black};

	--color-game-background: #1f262f;

	--color-menu-icon: #a3adbf;

	--color-header-title: #c8d7ef;
	--color-header-puzzle: #5c6573;

	--color-field-cell-shadow: rgba(0, 0, 0, 0.3);
	--color-field-cell-background: #0e151e;
	--color-field-cell-background-right: rgba(0, 128, 0, 0.2);
	--color-field-cell-border-right: rgba(0, 128, 0, 0.15);
	--color-field-cell-background-wrong: rgba(255, 0, 0, 0.2);
	--color-field-cell-border-wrong: rgba(255, 0, 0, 0.15);

	--opacity-field-cell-selected-border: 0.65;

	--color-keyboard-key-text: #2e3847;
	--color-keyboard-key-background: #9a9aa5;
	--color-keyboard-key-background-wrong: rgba(255, 0, 0, 0.3);

	--opacity-keyboard-key-disabled: 0.2;

	--color-chances-stars: #293641;

	--color-menu-foreground-text: #6e7480;
	--color-menu-foreground-close: #6e7480;
	--color-menu-background: #0e151f;
	--color-menu-overlay: #000000;
	--opacity-menu-overlay: 0.8;

	--color-dialog-foreground-text: var(--color-menu-foreground-text);
	--color-dialog-foreground-close: var(--color-menu-foreground-close);
	--color-dialog-background: var(--color-menu-background);
	--color-dialog-overlay: var(--color-menu-overlay);
	--opacity-dialog-overlay: 0.85;

	--color-sliding-message-background: #a94498;
	--color-sliding-message-text: white;

	--color-toast-text: white;
	--color-toast-background: #a94498;
	--color-toast-shadow: rgba(0, 0, 0, 0.2);
}

@mixin vars-colors-dark-highContrast {
	--color-menu-icon: #c3cdff;

	--color-header-title: #e8f7ff;
	--color-header-puzzle: #7c8593;
	--opacity-header-puzzle: 0.9;

	--color-keyboard-key-text:#0e1827;

	--color-menu-foreground-text: #8e94a0;
	--color-menu-foreground-close: #8e94a0;
}

/* Normal media queries for color theme */

:root {
	@include vars-colors-light;
}

body.highContrast-enabled {
	@include vars-colors-light;
	@include vars-colors-light-highContrast;
}

@media (prefers-color-scheme: dark) {
	:root {
		@include vars-colors-dark;
	}

	body.highContrast-enabled {
		@include vars-colors-dark;
		@include vars-colors-dark-highContrast;
	}
}

/* Applied classes for color theme */

body.lightMode-always-light {
	@include vars-colors-light;

	&.highContrast-enabled {
		@include vars-colors-light-highContrast;
	}
}

body.lightMode-always-dark {
	@include vars-colors-dark;

	&.highContrast-enabled {
		@include vars-colors-dark-highContrast;
	}
}
