.table {
	background-color: var(--color-game-background);
	width: 100%;
	border-radius: 4px;
	padding: 4px;

	& td {
		position: relative;
		align-content: center;
		text-align: center;
		width: 50%;
		padding: 8px 10px;
		font-size: 16px;

		& > div {
			margin: auto;
		}
	}
}

.start {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 2em 0;
}
